import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import takeTime from '../../constants/takeTime'
import { PhoneInput } from "react-international-phone";
import CardV1 from '../components/assets/images/CardV1.svg'
import CardV2 from '../components/assets/images/CardV2.svg'
import "react-international-phone/style.css";
import {
  Checkbox,
} from '@mui/material'
import { mainApi } from '../../store/services/mainApi'
import { setItemsCart } from '../../store/slices/allItemsCart'
import { setIdLastOrder, setIsLogin, setPaymentMetod, setWarnModal } from '../../store/slices/contextUI'
import {Modal, Box, TextField, Button} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import unitEqual from '../../constants/unitEqual'
import axios from 'axios'
import useFindItemByUnit from '../../hooks/useFindItemByUnit'
import { setUserAcc } from '../../store/slices/accountData'
import { PhoneNumberUtil } from 'google-libphonenumber';
import orderDataConst from '../../constants/OrderData'
import SaleTotalCart from './SaleTotalCart/SaleTotalCart';
import { setClearSaleCart, setItemsSaleCart } from '../../store/slices/saleCart';
import useCalculateSaleCart from '../../hooks/SalePageHooks/useCalculateSaleCart';
import { salePageApi } from '../../store/services/salePageApi';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const SaleNewOrderPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tradeNameParams = useParams().tradeName
  const platformParams = useParams().platform
  // const saleId = useParams().saleId
  const saleId = '66d96fc9c9b0239f42e97458'
  const date = new Date();
  const formattedDate = !(date.getHours()>0&&date.getHours()<9) ? new Date(date.setDate(date.getDate() + 1)).toISOString()?.split('T')[0] : new Date().toISOString()?.split('T')[0];
  // console.log('Params:'+tradeNameParams);
  const allItemsCart = useSelector((state) => state.saleCart.saleCart)
  const [view, setView] = useState(1)
  const calcCart = useCalculateSaleCart()
  

  const [replacedCart, setReplacedCart] = useState([])
  useEffect(() => {
    setReplacedCart(allItemsCart.map((el) => {
      if (unitEqual.find((item) => item.sku === el.sku)) {
        const itemFind = useFindItemByUnit(el, el.currentUnit)
        return {
          ...el,
          quantityOrder: parseFloat(el.quantity) * itemFind,
          quantity: parseFloat(el.quantity) * itemFind,
          currentUnit: 'kg',
          currientRequest: `${el.quantity} ${el.currentUnit}`,
          ourPrice: el.ourPrice,
          ourPriceDP: el.ourPrice,
          ourPriceSDP: el.ourPrice,
          ourPriceCP: el.ourPrice,
        }
      } else {
        return {...el,
          currientRequest: `${el.quantity} ${el.currentUnit}`,
          ourPrice: el.ourPrice,
          ourPriceDP: el.ourPrice,
          ourPriceSDP: el.ourPrice,
          ourPriceCP: el.ourPrice,}
      }
    }))
  }, [allItemsCart])


  const [createPreOrder, { data: resultPreOrder, isSuccess: isSuccessPreOrder }] = salePageApi.useCreatePreOrderAndUpgradeMutation()

  useEffect(() => {
    console.log(resultPreOrder);
    console.log(isSuccessPreOrder);
  }, [createPreOrder, resultPreOrder, isSuccessPreOrder])

  const cardData = [{img: CardV1, number: '**1234', name: 'Card 1'}, {img: CardV2, number: '**5678', name: 'Card 2'}]
  
  const [editTab, setEditTab] = useState(1)
  const [showPayBox, setShowPayBox] = useState(false)
  const [cardActive, setCardActive] = useState(cardData[0])
  const [handleOrder, setHandleOrder] = useState(false)

  const localUserData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null  
  const [nameUser, setNameUser] = useState(localUserData?.name ? localUserData.name : '')
  const [description, setDescription] = useState(localUserData?.tradeNameAdress ? localUserData.tradeNameAdress : '')
  const [number, setNumber] = useState(localUserData?.phone ? localUserData.phone : '')
  const [tradeName, setTradeName] = useState(localUserData?.tradeNameCode ? localUserData.tradeNameCode : '')
  const isValid = isPhoneValid(number);
  

  const method = useSelector((state) => state.contextUI.paymentMetod) 
  useEffect(() => {
    setOrderData((prev) => ({
      ...prev, deliveryDate: formattedDate
    }))
  }, [])

  const changeDelDate = (e, type) => {
    switch (type) {
      case 'day': {
        setEDate(e)
        break}
      case 'time': {
        setETime(e)
        break}
    }
  }
  const [eTime, setETime] = useState('PLM ☀️')
  const [eDate, setEDate] = useState(formattedDate)
  
  
  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}.${month}.${year}`;
  };
  
  const [orderData, setOrderData] = useState(orderDataConst)

  const isWarn = useSelector((state) => state.contextUI.uiModal.warnModal)
  const userData = useSelector(state => state.accountData.userAcc)
  
  useEffect(() => {
    console.log(orderData);
  }, [orderData])

  useEffect(() => {
    setOrderData((prev) => ({
      ...prev,
      paymentType: method,
    }))
  }, [method])
   

  const handleSendOrder = async () => {
    setHandleOrder(true)
    let cart = replacedCart?.map((el) => 
      ({...el,
        marketPrice: el.SPOPrice ? el.SPOPrice : el.marketPrice,
        marketPriceDP: el.SPOPrice ? el.SPOPrice : el.marketPriceDP,
        marketPriceSDP: el.SPOPrice ? el.SPOPrice : el.marketPriceSDP,
        marketPriceCP: el.SPOPrice ? el.SPOPrice : el.marketPriceCP,
      }))
    await createPreOrder({id: saleId,
      data:
      {...orderData,
      deliveryDate: formatDate(eDate)+', '+eTime,
      deliveryTime: eTime,
      address: description !== '' ? description : '',
      items: cart,
      paymentType: tradeNameParams !== undefined ? 'Credit Line' : method ,
      tradeName: tradeNameParams !== '' ? tradeNameParams : userData?.tradeName !== '' ? userData.tradeName : '',
      userAcc: {
        ...userData,
        name: nameUser !== '' ? platformParams !== undefined ? `${nameUser} (${platformParams})`: nameUser  : '',
        phone: number !== '' ? number.replace('+', '') : '',
        tradeName: tradeNameParams !== '' ? tradeNameParams : userData?.tradeName !== '' ? userData.tradeName : '',
      }
    }}).then(async (res) => {
      setHandleOrder(false)
      dispatch(setWarnModal(false))
      
      dispatch(setClearSaleCart())
      navigate('/group/myOrders')
      
    }).catch((err) => {
      alert('Try again!!!')
    })
  }

  const [emptyCartWarn, setEmptyCartWarn] = useState(false)

  return (<>
    <div className='NewOrderPage'>
      <div className='NewOrderPage__container'>
        <div className='NewOrderPage__mainEditOrder'>
          <div className='NewOrderPage__mainEditOrder__item'>
            {/* <div 
              className='NewOrderPage__mainEditOrder__item__header'
              onClick={() => setEditTab(0)}
              style={{color:  editTab !== 0 ? '#b0b0b0' : '#212b36',
              width: editTab === 0 ? '100%' : '98%',
            }}
            >
              1. Billing Details
              <img 
                src={ArrowUp} 
                alt="" 
                style={{transform: editTab === 0 ? 'rotate(180deg)' : '',
                filter:  editTab !== 0 ? 'brightness(0.5)' : '',
                }}/>
            </div> */}
            {editTab === 0 && 
            <div className='NewOrderPage__mainEditOrder__item__body'>
              <div className='NewOrderPage__mainEditOrder__item__body__inputRow'>
                <input 
                  className='NewOrderPage__mainEditOrder__item__body__inputRow__input'
                  type="text" 
                  value={orderData.userAcc.name}
                  onChange={(e) => {
                    setOrderData((prev) => ({...prev, userAcc: {...prev.userAcc, name: e.target.value}}))
                  }}
                  placeholder='first name*'
                />
                {/* <input 
                  className='NewOrderPage__mainEditOrder__item__body__inputRow__input'
                  type="email" 
                  value={orderData.userAcc.email}
                  onChange={(e) => {setOrderData((prev) => ({...prev, userAcc: {...prev.userAcc, email: e.target.value}}))}}
                  placeholder='Email'
                /> */}
              </div>
              <div className='NewOrderPage__mainEditOrder__item__body__inputRow'>
                <input 
                  className='NewOrderPage__mainEditOrder__item__body__inputRow__input'
                  type="text" 
                  value={orderData.address}
                  onChange={(e) => {setOrderData((prev) => ({...prev, address: e.target.value}))}}
                  placeholder='Address*'
                />
                <PhoneInput
                  className='NewOrderPage__mainEditOrder__item__body__inputRow__input__phoneInput'
                  defaultCountry="es"
                  value={orderData.userAcc.phone}
                  onChange={(e) => {setOrderData((prev) => ({...prev, userAcc: {...prev.userAcc, phone: e}}))}}
                />
              </div>
              <div className='NewOrderPage__mainEditOrder__item__body__inputRow'>
                {/* <textarea 
                  rows={2}
                  className='NewOrderPage__mainEditOrder__item__body__inputRow__inputRow'
                  type="text" 
                  value={orderData.description}
                  onChange={(e) => {setOrderData((prev) => ({...prev, description: e.target.value}))}}
                  placeholder='Destination'
                /> */}
              </div>
              
            </div>}
          </div>
          <div className='NewOrderPage__mainEditOrder__item'>
            {tradeNameParams == undefined && <div 
              className='NewOrderPage__mainEditOrder__item__header'
              onClick={() => setEditTab(1)}
              style={{color:  editTab !== 1 ? '#b0b0b0' : '#212b36',
              width: editTab === 1 ? '100%' : '98%',
            }}
            >
              {/* 1. */}
               Payment
              {/* <img 
                src={ArrowUp} 
                alt="" 
                style={{transform: editTab === 1 ? 'rotate(180deg)' : '',
                filter:  editTab !== 1 ? 'brightness(0.5)' : '',
                }}/> */}
            </div>}
            {editTab === 1 && 
            <div className='NewOrderPage__mainEditOrder__item__body'>
              {tradeNameParams === undefined && <div className='NewOrderPage__mainEditOrder__item__body__delivery'>
                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item'>
                  <Checkbox
                    className='NewOrderPage__mainEditOrder__item__body__delivery__item__checkbox'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E' } }}
                    checked={orderData.paymentType == 'Pick Up'}
                    onClick={() => {
                      setOrderData((prev) => ({...prev, paymentType: 'Pick Up'}))
                      dispatch(setPaymentMetod('Pick Up'));
                      localStorage.setItem('paymentMethod', 'Pick Up');
                    }}
                  />
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data'>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__title'>
                        Pick Up <span>Mercabarna</span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__price'>
                        {calcCart.pickUpPrice?.total.toFixed(2)}€
                      </div>
                    </div>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__save'>
                        <div>You save</div>
                        <span>
                          {calcCart.pickUpPrice?.save.toFixed(2)}€
                        </span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__percent'>
                        -{calcCart.percentageOrder?.pickUp}% <span style={{fontSize: '10px'}}>(coming soon)</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item'>
                  <Checkbox
                    className='NewOrderPage__mainEditOrder__item__body__delivery__item__checkbox'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E' } }}
                    checked={orderData.paymentType == 'Pay Online'}
                    onClick={() => {
                      setOrderData((prev) => ({...prev, paymentType: 'Pay Online'}))
                      dispatch(setPaymentMetod('Pay Online'));
                      localStorage.setItem('paymentMethod', 'Pay Online');
                    }}
                  />
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data'>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__title'>
                        Pay Online
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__price'>
                        {calcCart.payOnlinePrice?.total.toFixed(2)}€
                      </div>
                    </div>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__save'>
                        <div>You save</div>
                        <span>
                          {calcCart.payOnlinePrice?.save.toFixed(2)}€
                        </span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__percent'>
                        -{calcCart.percentageOrder?.payOnline}%
                      </div>
                    </div>
                  </div>
                </div>
                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__payment'>
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__payment__item'>
                    <img src={cardActive.img} alt="" />
                    <div>{cardActive.number}</div>
                    <div 
                      onClick={() => setShowPayBox(!showPayBox)}
                      className='NewOrderPage__mainEditOrder__item__body__delivery__item__payment__item__change'
                    >
                      Change
                    </div>
                  </div>
                  {showPayBox &&
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__payBox'>
                      {cardData.map((item, index) => (
                        <div 
                          className='NewOrderPage__mainEditOrder__item__body__delivery__item__payBox__item'
                          onClick={() => setCardActive(item)}
                          key={item.name}
                          style={{background: cardActive.name === item.name ? '#5FC56E' : '#ffffff', color: cardActive.name === item.name ? '#ffffff' : '#5FC56E'}}
                        >
                          <img src={item.img} alt="" />
                          <div>{item.number}</div>
                          <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__payBox__item__name'>{item.name}</div>
                        </div>
                      ))}
                    </div>
                  }
                </div>

                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item'>
                  <Checkbox
                    className='NewOrderPage__mainEditOrder__item__body__delivery__item__checkbox'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E' } }}
                    checked={orderData.paymentType == 'Credit Line'}
                    onClick={() => {
                      setOrderData((prev) => ({...prev, paymentType: 'Credit Line'}))
                      dispatch(setPaymentMetod('Credit Line'));
                      localStorage.setItem('paymentMethod', 'Credit Line');
                    }}
                  />
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data'>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__title'>
                        Credit Line
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__price'>
                        {calcCart.CreditLinePrice?.total.toFixed(2)}€
                      </div>
                    </div>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__save'>
                        <div>You not save</div>
                        <span>
                          +{calcCart.CreditLinePrice?.save.toFixed(2)}€
                        </span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__percent'>
                        +{calcCart?.percentageOrder?.creditLine}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              <div className='NewOrderPage__mainEditOrder__item__body__timetitle'>
                Elije su horario de la entrega
                <div className='NewOrderPage__mainEditOrder__item__body__timetitle__box'>
                  {takeTime.map((el) => 
                  <div
                    key={el.title}
                    className='NewOrderPage__mainEditOrder__item__body__timetitle__box__item'
                    onClick={() => {changeDelDate(el.title, 'time')}}
                    style={{background: eTime === el.title ? '#5FC56E' : ''}}
                  >
                    {el.time}
                  </div>)}
                </div>
                <div><br />Si se necesita una hora especial, apuntala en los comentarios más adelante por favor</div>
              </div>
              <div className='NewOrderPage__mainEditOrder__item__body__timetitle'>
                <b>Confirma o cambia la fecha 📆</b>
                <div className='NewOrderPage__mainEditOrder__item__body__timetitle__box'>
                  <input 
                    style={{
                      width: '100%', 
                      border: '2px solid #5FC56E', 
                      borderRadius: '10px', 
                      padding: '10px', 
                      resize: 'none', 
                      outline: 'none',
                      font: 'Montserrat',
                      fontSize: '18px'
                    }}
                    type="date"
                    min={formattedDate} 
                    value={eDate}   
                    onChange={(e) => {setEDate(e.target.value); console.log(e.target.value);}}
                    />
                </div>
                    <br />
                <span>Recuerda que <b>no hay reparto los Miércoles y los Domingos</b></span>
              </div>
              <div className='NewOrderPage__mainEditOrder__item__body__timetitle'>
                Dejar el comentario y los detalles
                <div className='NewOrderPage__mainEditOrder__item__body__timetitle__box'>
                  <textarea 
                    style={{
                      width: '100%', 
                      border: '2px solid #5FC56E', 
                      borderRadius: '10px', 
                      padding: '10px', 
                      resize: 'none', 
                      outline: 'none',
                      font: 'Montserrat',
                      fontSize: '18px'
                    }}
                    value={orderData.description}
                    onChange={(e) => {setOrderData((prev) => ({...prev, description: event.target.value}))}}
                  />
                </div>
              </div>

            </div>}
          </div>
          {/* <div className='NewOrderPage__mainEditOrder__item'>
            <div 
              className='NewOrderPage__mainEditOrder__item__header'
              onClick={() => setEditTab(2)}  
              style={{color:  editTab !== 2 ? '#b0b0b0' : '#212b36',
              width: editTab === 2 ? '100%' : '98%',
            }}
            >
              3. Deduct bonus points
              <img 
                src={ArrowUp} 
                alt="" 
                style={{transform: editTab === 2 ? 'rotate(180deg)' : '',
                filter:  editTab !== 2 ? 'brightness(0.5)' : '',
                }}/>
            </div>
            {editTab === 2 && 
            <div className='NewOrderPage__mainEditOrder__item__body'>
              
            </div>} 
          </div> */}
        </div>
      </div>
      
      <SaleTotalCart/>
    </div>
    <div
      className='NewOrderPage__mainEditOrder__sendOrder'
      onClick={() => {if (allItemsCart.length > 0) {
        dispatch(setWarnModal(true))
        } else {
          setEmptyCartWarn(true)
        }}}
    >
      Enviar el pedido
    </div>
    {/* {isWarn &&
    <WarningModal timeout={2000}>
        Great!!!
        <br/>
        You need to log in or register an account!
    </WarningModal> */}
    <Modal open={isWarn} onClose={() => dispatch(setWarnModal(false))}>
      <Box 
      className='NewOrderPage__mainEditOrder__sendOrder__modal'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        border: '3px solid #5FC56E',
        fontFamily: 'Montserrat',
        fontSize: '17px',
        color: '#5FC56E',
        borderRadius: 6,
        gap: 2,
        p: 4,
      }}>
        <b style={{marginBottom: '10px'}}>Genial👍<br/>
        Ahora dinos tus  detalles para acabar el pedido:</b>
        <TextField
          type="text"
          label="Tu nombre"     
          value={nameUser}
          onChange={(e) => setNameUser(e.target.value)} 
        />
        <div style={{display: 'flex', gap: '10px'}}>
          <TextField
            type="text"
            sx={{flex: 2}}
            label="El nombre del local"
            value={description}
            disabled={tradeNameParams !== undefined}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            type="text" 
            sx={{flex: 1}}
            label="Tu código"
            value={tradeName}
            disabled={tradeNameParams !== undefined}
            onChange={(e) => setTradeName(e.target.value)}
          />
        </div>
        <PhoneInput
          className='NewOrderPage__phoneInput'
          defaultCountry="es"
          value={number}
          onChange={(e) => {setNumber(e)}}
        />
        {!handleOrder ? <Button
          variant='contained'
          disabled={!isValid}
          sx={{
            width: '100%',
            fontFamily: 'Montserrat',
            backgroundColor: '#5FC56E',
            '&:hover': {
              backgroundColor: '#5FC56E',
            },
          }}
          onClick={() => {nameUser !== '' && number.length > 5 && description !== '' ? handleSendOrder() :
        alert('Rellene todos los campos')}}
        >
          {!isValid ? 'Introduzca el número correcto' : 'Confirmar'}
        </Button>: <CircularProgress/>} 
      </Box> 
    </Modal>

    <Modal open={emptyCartWarn} onClose={() => setEmptyCartWarn(false)}>
      <Box 
      className='NewOrderPage__mainEditOrder__sendOrder__modal'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        border: '3px solid #5FC56E',
        fontFamily: 'Montserrat',
        fontSize: '17px',
        color: '#5FC56E',
        borderRadius: 6,
        gap: 2,
        p: 4,
      }}>
        <b style={{marginBottom: '10px'}}>Atención❗️<br/>
        No es posible enviar el pedido sin ningún artículo elegido! <br />
        <br />
        Por favor elige los productos necesarios
        </b>
        
        {!handleOrder ? <Button
          variant='contained'
          sx={{
            width: '100%',
            fontFamily: 'Montserrat',
            backgroundColor: '#5FC56E',
            '&:hover': {
              backgroundColor: '#5FC56E',
            },
          }}
          onClick={() => {
            setEmptyCartWarn(false)
            if (tradeNameParams !== undefined) {
              if (platformParams !== undefined) {navigate(`/category/${platformParams}/${tradeNameParams}`)} else {
                navigate(`/category/${tradeNameParams}`)
              }
            } else {
              navigate('/home')
            }
          }}
        >
          Confirmar
        </Button>: <CircularProgress/>} 
      </Box> 
    </Modal>
    {/* } */}
    </>
  )
}

export default SaleNewOrderPage