import React from 'react'
import './ModalUI.scss'
import CloseIcon from '../assets/images/closeElem.svg'

const ModalUI = ({children, setIsOpenModal, sx={}, cl=''}) => {
  return (
    <div className={'PatternModal '+cl} onClick={() => setIsOpenModal(false)}>
        <div 
          className='PatternModal__content' 
          onClick={(e) => e.stopPropagation()} 
          style={sx}
        >
          <div className='PatternModal__content__close'>
            <img src={CloseIcon} alt="" onClick={() => setIsOpenModal(false)}/>
          </div>
          <div className='PatternModal__content__children' onClick={(e) => e.stopPropagation()}>
            <hr style={{margin: '10px auto 0 auto', color: '#252525', width: '90%'}}/>
              {children}
          </div>
        </div>
      </div>
  )
}

export default ModalUI