import React, { useState } from 'react'
import ModalUI from './../../ModalUI/ModalUI';
import TemplateItemEdit from './TemplateItemEdit/TemplateItemEdit';
import BackIcon from '../../assets/images/ArrowBack.svg'
import { Switch } from '@mui/material';

const TemplEditModal = ({ 
  setEditModal, 
  editedItems, 
  setEditedItems, 
  setSearchModal, 
  setIsOpenModal,
  setSendOrderModal}) => {

  // console.log(editedItems);
  
  const [hideImage, setHideImage] = useState(false)

  return (
    <ModalUI setIsOpenModal={setEditModal} sx={{overflow: 'hidden'}}>
      <div 
        // onClick={() => {setHideImage(!hideImage)}}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content',
          fontSize: '12px',
          fontWeight: 500,
          padding: '5px 10px 0px 10px',
          margin: 'auto'
        }}
      >
        Sin foto
        <Switch  
          className='ProfileMobile__status__switch'
          checked={hideImage}
          onChange={() => setHideImage(!hideImage)}
          sx={{
            width: '68px',
            '& .MuiSwitch-root .MuiSwitch-sizeMedium': {
              width: '66px'},
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: '#fff !important',
              widows: '20px',
            },
            '& .Mui-checked+.MuiSwitch-track': {
              backgroundColor: '#5FC56E !important'},
            '& .MuiSwitch-track': {

              height: '24px',
              width: '70px',
              borderRadius: '14px',

              transform: 'translateX(-5px) translateY(-5px)'
            }
            }}
          />
      </div>
      <div className='OrderSwiper__itemsList__editItems'>
        {editedItems?.map((el, index) => 
          <TemplateItemEdit
            item={el} 
            key={el._id}
            setEditedItems={setEditedItems} 
            editedItems={editedItems}   
            hideImage={hideImage}
          />
        )}  
      </div>
      <hr style={{margin: '0 auto', color: '#252525', width: '90%'}}/>
      <button
        style={{
          width: '200px',
          height: 'fit-content',
          borderRadius: '32px',
          padding: '10px',
          backgroundColor: '#5FC56E',
          color: 'white',
          border: 'none',
          marginTop: '20px',
          outline: 'none',
          cursor: 'pointer',
          letterSpacing: '1px',
          fontWeight: 'bold',
          transition: '0.3s',

          '&:hover': {
            backgroundColor: '#5FC5AE',
          },
        }}
        onClick={() => {
          setSendOrderModal(true)
        }}
      >
        Clone Order
      </button>
      <button
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '32px',
          padding: '10px',
          backgroundColor: '#5FC56E',
          color: 'white',
          border: 'none',
          marginTop: '20px',
          outline: 'none',
          cursor: 'pointer',
          letterSpacing: '1px',
          fontWeight: 'bold',
          transition: '0.3s',
          fontSize: '32px',
          textAlign: 'center',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '0px',
          right: '15px',

          '&:hover': {
            backgroundColor: '#5FC5AE',
          },
        }}
        onClick={() => {
          setSearchModal(true)
        }}
      >
        +
      </button>
      <button
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '32px',
          padding: '10px',
          backgroundColor: '#5FC56E',
          color: 'white',
          border: 'none',
          marginTop: '20px',
          outline: 'none',
          cursor: 'pointer',
          letterSpacing: '1px',
          fontWeight: 'bold',
          transition: '0.3s',
          fontSize: '32px',
          textAlign: 'center',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '0px',
          left: '15px',

          '&:hover': {
            backgroundColor: '#5FC5AE',
          },
        }}
        onClick={() => {
          setIsOpenModal(true)
          setEditModal(false)
        }}
      >
        <img src={BackIcon} alt="" style={{filter: 'brightness(100)'}}/>
      </button>
    </ModalUI>
  )
}

export default TemplEditModal