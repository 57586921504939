import { useSelector } from 'react-redux';
import useUpdatePrice from './useUpdatePrice';
import { mainApi } from '../store/services/mainApi'
import { useParams } from 'react-router-dom'

const useSplitCart = () => {
  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  });
  const allItemsCart = useUpdatePrice(useSelector((state) => state.allItemsCart.allItemsCart), allProductsData, isLoading)
  const allItemsSaleCart = useSelector((state) => state.saleCart.saleCart)
  const isSalePage = location.pathname.includes('/group')
  // const isSalePage = useParams().saleId
  // console.log(allItemsSaleCart)
  // const allItemsUpdatedCart = useUpdateCart()
  const allCart = isSalePage ? [...allItemsSaleCart].sort((a, b) => a.name.localeCompare(b.name)) : [...allItemsCart].sort((a, b) => a.name.localeCompare(b.name));
  const groupedData = allCart.reduce((acc, item) => {
    const groupKey = item.dias ? `dias${item.dias.toString()}` : item.stock == '1' ? 'stock' :  item.stock == '0' ? 'noStock' : 'tbc';
    
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    
    acc[groupKey].push(item);
    
    return acc;
  }, {});
  // console.log(groupedData);
  return {groupedData, isLoadingSplitCart: isLoading} ;
}

export default useSplitCart