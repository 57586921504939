import React, { useState } from 'react'
import './SearchListItem.scss'
import SearchListItemCounter from './SearchListItemCounter/SearchListItemCounter';


const SearchListItem = ({item, setEditedItems, editedItems}) => {
  const foundItem = editedItems?.find((el) => el.sku === item.sku);
  // console.log(editedItems);
  const [dataItem, setDataItem] = useState({quantity: foundItem?.quantity || 0, marketPriceCP: item.marketPriceCP});

  const handleInputChange = (event) => {
    setDataItem({ ...dataItem, quantity: event.target.value });
  };
  
  const addItem = () => {
    if (!foundItem) {
      setEditedItems([...editedItems, {...item, quantity: 1, quantityOrder: 1}].sort((a, b) => a.name.localeCompare(b.name)));
    }
  }
  return (
    <div className='SearchListItem'>
      <div className='SearchListItem__data'>
        <div className="SearchListItem__data__img">
          <img src={item.img} alt="" />
        </div>
        <div className="SearchListItem__data__title">
          {item.name}
        </div>
      </div>

      <div className='SearchListItem__dataParams'>
          {/* <div className='SearchListItem__dataParams__params'>
            Quantity: 
            <input 
              type="number"
              value={dataItem.quantity}
              onChange={handleInputChange}
            />
          </div>
          <div className='SearchListItem__dataParams__params'>
            Price: 
            <button 
              style={{
                color: foundItem ? '#5fc56e' : 'fff',
                backgroundColor: foundItem ? '#fff' : '#5fc56e',
              }}
              onClick={() => {
                if (!foundItem) {
                  setEditedItems([...editedItems, {...item, quantity: 1, quantityOrder: 1}].sort((a, b) => a.name.localeCompare(b.name)));
                }
              }}
            >
              <span>{(dataItem.marketPriceCP).toFixed(2)}€</span>
              {!foundItem &&<div>+</div>}
            </button>
          </div> */}
          <SearchListItemCounter
            item={item}
            foundItem={foundItem}
            addItem={addItem}
          />
      </div>
    </div>
  )
}

export default SearchListItem