import React, { useMemo } from 'react'


const updateArray = (list, lastList) => {
  const updatedList = list?.map((el) => {
    const element = lastList?.find((item) => item?.sku === el?.sku);
    if (element) {
      return {
        ...element,
        quantity: el.quantity,
        quantityOrder: el.quantityOrder,
        currentUnit: el.currentUnit,
        spoPrice: el.spoPrice,
        timestamp: el.timestamp,
      }
    }
    return el;
  })
  return updatedList
}

const useUpdatePrice = (list, lastList, isLoading) => {
  if (!lastList || isLoading) {
    return []; 
  }

  const updatedList = updateArray(list, lastList);

  if (!updatedList || updatedList.length === 0) {
    return []; // или возвращайте какой-то дефолтный элемент
  }

  return updatedList?.sort((a, b) => a?.name?.localeCompare(b.name));
};

export default useUpdatePrice