import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import Lottie from 'lottie-react'
import ErrorLotieData from '../../lotties/avocado404.json'
import FastTempleOrder from '../components/FastTempleOrder/FastTempleOrder'
import PreviewNavMobile from '../components/Content/PreviewCategories/PreviewNav/PreviewNavMobile/PreviewNavMobile'
import PreviewNav from '../components/Content/PreviewCategories/PreviewNav/PreviewNav'
import PreviewFilter from '../components/Content/PreviewCategories/PreviewFilter/PreviewFilter'
import HeaderMobile from '../components/Header/HeaderMobile/HeaderMobile'
import Header from '../components/Header/Header'
import useSendLog from '../../hooks/useSendLog'

const ErrorPage = () => {
  const location = useLocation()
  const errorMassage = location.state?.errorMassage
  console.log('error: '+errorMassage);
  
  const [error, setError] = useState(null);
  const [errorFile, setErrorFile] = useState(null);

  window.onerror = (message, source, lineno, colno, error) => {
  setError({message: error.message, lineno: lineno, source: source, error: error});
  return true;
};

  window.addEventListener("error", (error) => {
    setErrorFile(error.filename);
  });
  const sendErrorLog = useSendLog('Error', { 
    errorMassage: errorMassage,
    error: error,
    errorFile: errorFile
  });
  useEffect(() => {
    sendErrorLog()
  }, [])

  return (
    <>
      <Header />
      <HeaderMobile/>
      <PreviewFilter />
      <PreviewNav />
      <PreviewNavMobile />
      <div style={{ 
        width: '400px',
        height: 'fit-content',
        margin: 'auto',
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center'
        
      }}>
        <span
          style={{ fontSize: '30px', fontWeight: 'bold', color: '#252525', marginBottom: '-100px' }}
        >Oops!</span>
        <div style={{ 
        width: '400px',
        height: '400px',
        margin: 'auto',
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center'
        }}>
          <Lottie
            animationData={ErrorLotieData}
            autoplay={true}
            height={200}
            width={400}
          />
        </div>
        <span
          style={{ fontSize: '30px', fontWeight: 'bold', color: '#252525', textAlign: 'center', marginTop: '-60px' }}
        >
          Por favor recarga la página
        </span>
        <button
          style={{
            width: '200px',
            height: 'fit-content',
            borderRadius: '10px',
            padding: '10px',
            backgroundColor: '#5FC56E',
            color: 'white',
            border: 'none',
            marginTop: '30px',
            outline: 'none',
            cursor: 'pointer',
            letterSpacing: '1px',
            fontWeight: 'bold',
            transition: '0.3s',

            '&:hover': {
              backgroundColor: '#5FC5AE',
            },
          }}
          onClick={() => window.location.reload()}
        >
          Recargarme
        </button>
      </div>
    </>
  )
}

export default ErrorPage