import React from 'react'
import { useSelector } from 'react-redux'
import useFindItemByUnit from '../useFindItemByUnit'

const useCalculateSaleCart = () => {
  const allItemsUpdatedCart = useSelector((state) => state.saleCart.saleCart)


  const percentageOrder = {
    pickUp: 15,
    payOnline: 1.5,
    creditLine: 1,
  }

  const totalPriceNonIVA = allItemsUpdatedCart?.map((el) => el?.spoPrice ? (( el.spoPrice * (el.quantity*useFindItemByUnit(el, el.currentUnit)))/(100+parseInt(el.IVACP))*100) : ((el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit)))/(100+parseInt(el.IVACP))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const IVA = allItemsUpdatedCart?.map((el) => el?.spoPrice ? (el.spoPrice * (el.quantity*useFindItemByUnit(el, el.currentUnit)))-((el?.spoPrice ? el.spoPrice : el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit)))/(100+parseInt(el.IVACP))*100) :  (el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit)))-((el?.spoPrice ? el.spoPrice : el.marketPriceCP * (el.quantity*useFindItemByUnit(el, el.currentUnit)))/(100+parseInt(el.IVACP))*100)).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const deliveryPrice = 0
  const totalPrice = allItemsUpdatedCart?.map((el) => el?.spoPrice ? el.spoPrice  * (el.quantity*useFindItemByUnit(el, el.currentUnit)) :  el.marketPriceCP  * (el.quantity*useFindItemByUnit(el, el.currentUnit))).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const pickUpPrice = {
    total: totalPrice - totalPrice / 100 * percentageOrder.pickUp,
    save: totalPrice / 100 * percentageOrder.pickUp
  }
  const payOnlinePrice = {
    total: totalPrice - totalPrice / 100 * percentageOrder.payOnline,
    save: totalPrice / 100 * percentageOrder.payOnline
  }
  const CreditLinePrice = {
    total: totalPrice - totalPrice / 100 * -percentageOrder.creditLine,
    save: totalPrice / 100 * percentageOrder.creditLine
  }

  return {
    totalPriceNonIVA,
    IVA,
    deliveryPrice,
    totalPrice,
    pickUpPrice,
    payOnlinePrice,
    CreditLinePrice,
    percentageOrder
  }
}

export default useCalculateSaleCart