import { CircularProgress, Slider, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSendLog from '../../../hooks/useSendLog'
import { setPriceIvaMode, setProMode } from '../../../store/slices/contextUI'
import useSplitCart from '../../../hooks/useSplitCart'

import useCalculateCart from '../../../hooks/useCalculateCart'
import useUpdateCart from '../../../hooks/useUpdateCart'
import { useParams } from 'react-router-dom';
import MapNewOrderList from '../../components/NewOrderPage/MapNewOrderList/MapNewOrderList'
import SaleTotalCartItem from './SaleTotalCartItem/SaleTotalCartItem'

const SaleTotalCart = () => {
  const dispatch = useDispatch()
  const { groupedData: sortItemsCart, isLoadingSplitCart } = useSplitCart();
  
  
  const calcCart = useCalculateCart()
  const allItemsSaleCart = useSelector((state) => state.saleCart.saleCart)
  console.log(allItemsSaleCart);
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)

  const [listType, setListType] = useState(false)

  return (
    <div className='NewOrderPage__cart'>
      <div className='NewOrderPage__cart__viewBtn' onClick={() => setListType(!listType)}>
        {!listType ? 
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="white" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48m-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6 6m-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12m0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12m0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373 12 12m-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36s16.118-36 36-36s36 16.118 36 36m0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36s16.118-36 36-36s36 16.118 36 36m0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36s16.118-36 36-36s36 16.118 36 36"></path></svg> :
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="white" d="M3 4.5h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2m0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM1 2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 2m0 12a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 14"></path></svg>
        }
      </div>
      <div className='NewOrderPage__cart__title'>
        Su pedido
      </div>
      <div className='NewOrderPage__cart__body'> 
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <span style={{color: '#A4A4A4', marginLeft: '83px', marginBottom: '-14px'}}>IVA</span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            <div style={{marginRight: '-6px'}}>
              <span style={{color: '#A4A4A4'}}>Los precios</span> sin
            </div>
            <Switch  
              className='ProfileMobile__status__switch'
              checked={ivaSwitch}
              onChange={() => {dispatch(setPriceIvaMode(!ivaSwitch))}}
              sx={{
                width: '68px',
                '& .MuiSwitch-root .MuiSwitch-sizeMedium': {
                    width: '66px'},
                '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#fff !important',
                    widows: '20px',
                },
                '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: '#5FC56E !important'},
                '& .MuiSwitch-track': {

                        height: '24px',
                        width: '70px',
                        borderRadius: '14px',

                        transform: 'translateX(-5px) translateY(-5px)'
                    }
                }}
              />
              <div style={{marginLeft: '-16px'}}>
                сon
              </div>
          </div>
        </div>
        {isLoadingSplitCart && 
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress size={30} sx={{color: '#5FC56E'}}/>
        </div>} 
        {!listType ? <div>
          {sortItemsCart?.stock?.length > 0 && <>
            <div 
              className='NewOrderPage__cart__body__title'
              style={{color: '#5DC571', border: '1px solid #5DC571'}}
            >
              Disponible 99%
            </div>
            <div style={{ backgroundColor: '#5DC57146', padding: '10px', borderRadius: '10px', marginBottom: '20px'}}>
              {sortItemsCart?.stock?.map((item, index) =>
                <SaleTotalCartItem item={item} key={item?.title+item?.sku} />
              )}
            </div>
          </>}
          {sortItemsCart?.noStock?.length > 0 && <>
            <div 
              className='NewOrderPage__cart__body__title'
              style={{color: '#5DC571', border: '1px solid #5DC571'}}
            >
              Esta
            </div>
            <div style={{ backgroundColor: '#5DC57146', padding: '10px', borderRadius: '10px', marginBottom: '20px'}}>
              {sortItemsCart?.noStock?.map((item, index) =>
                <SaleTotalCartItem item={item} key={item?.title+item?.sku} />
              )}
            </div>
          </>}
          {sortItemsCart?.tbc?.length > 0 && <>
            <div 
              className='NewOrderPage__cart__body__title'
              style={{color: '#FFC727', border: '2px solid #FFC727'}}
            >
              Pendiente de confirmar...
            </div>
            <div style={{ backgroundColor: '#FFC72746', padding: '10px', borderRadius: '10px', marginBottom: '20px'}}>
              {sortItemsCart?.tbc?.map((item, index) =>
                <SaleTotalCartItem item={item} key={item?.title+item?.sku} />
              )}
            </div>
          </>}
          {Object?.keys(sortItemsCart)
          ?.filter((el) => el.includes('dias'))
          ?.sort((a, b) => a.localeCompare(b))
          ?.map((key) => 
            <>
              <div 
                key={key+'div'}
                className='NewOrderPage__cart__body__title'
                style={{color: '#007ED9', border: '2px solid #007ED9'}}
              >
                {key.replace('dias', '')} días para confirmar
              </div>
              <div style={{ backgroundColor: '#007ED946', padding: '10px', borderRadius: '10px', marginBottom: '20px'}}>
                {sortItemsCart[key]?.map((item, index) =>
                  <SaleTotalCartItem item={item} key={item?.title+item?.sku} />
                )}
              </div>
            </>
          )}
        </div> :
        <div>
          <div style={{ backgroundColor: 'transparent', padding: '10px', borderRadius: '10px', marginBottom: '20px'}}>
            {allItemsSaleCart?.map((item, index) =>
              <SaleTotalCartItem item={item} key={item?.title+item?.sku} />
            )}
          </div>
        </div>}
      </div>
      <div className='NewOrderPage__cart__footer'>
        <div className='NewOrderPage__cart__footer__prices'>
          <div className='NewOrderPage__cart__footer__prices__elem'>
              <div>Número de productos:</div>
              <div>{allItemsSaleCart?.length}</div>
          </div>
          {/* <div className='NewOrderPage__cart__footer__prices__elem'>
              <div>Precio de los productos :</div>
              <div>{calcCart.totalPriceNonIVA.toFixed(2)}€</div>
          </div>
          <div className='NewOrderPage__cart__footer__prices__elem'>
              <div>IVA:</div>
              <div>{calcCart.IVA.toFixed(2)}€</div>
          </div>
          <div className='NewOrderPage__cart__footer__prices__elem'>
              <div>Entrega:</div>
              <div>{calcCart.deliveryPrice.toFixed(2)}€</div>
          </div>
        </div>
        <div className='NewOrderPage__cart__footer__total'>
        <div className='NewOrderPage__cart__footer__total__title'>Total Importe Estimado:</div>
        <div className='NewOrderPage__cart__footer__total__price'>
          {calcCart.totalPrice.toFixed(2)}€
        </div> */}
        </div>
      </div>
    </div>
  )
}

export default SaleTotalCart