import React from 'react'
import { useSelector } from 'react-redux'
import MapElemLine from './MapNewOrderListElements/MapElemLine'
import MapElemTable from './MapNewOrderListElements/MapElemTable'


const MapNewOrderList = ({ list, color }) => {
  const proMode = useSelector((state) => state.contextUI.proMode)

  return (
    !list ? <></> :
    <div style={{ backgroundColor: `${color}46`, padding: '10px', borderRadius: '10px', marginBottom: '20px'}}>
      {
        proMode === 1 ? list?.map((item, index) =>  
          <MapElemLine item={item} list={list} key={item?.title+item?.sku}/>)
        : list?.map((item, index) => 
          <MapElemTable item={item} list={list} key={'table'+item?.title+item?.sku}/>
        )
      }
    </div>
  )
}

export default MapNewOrderList