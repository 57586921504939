const useReplaceAccent = ( str ) => {
  const accents = 'àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ';
  const noAccents = 'aaaaaceeeeiiiinooooouuuuyyAAAAACEEEEIIIINOOOOOUUUUY';
  let strRep = str
  for (let i = 0; i < accents.length; i++) {
    strRep = strRep?.replace(new RegExp(accents[i], 'g'), noAccents[i]);
  }
  return strRep;
}

export default useReplaceAccent