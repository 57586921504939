import React, { useState } from 'react'
import './SearchItem.scss'
import { mainApi } from '../../../../store/services/mainApi';
import searchIcon from '../../assets/images/Search_active.png'
import { Icon } from '@iconify/react/dist/iconify.js'

const SearchItem = ({searchWord, setSearchWord, sx={}}) => {

  return (
    <div className='SearchItem' style={sx}>
      <input 
        type="text"
        placeholder="Search..."
        value={searchWord}
        onChange={(e) => setSearchWord(e.target.value)}
      />
      <div
        className='SearchItem__Btn'  
        style={{display: searchWord !== '' ? 'flex' : 'none'}}
        onClick={() => setSearchWord('')}
      >
        x
      </div>
      {searchWord == '' && <img src={searchIcon} alt="" /> }
    </div>
  )
}

export default SearchItem