import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://tpomobi.shop' }),
  endpoints: (builder) => ({
    findAllWords: builder.query({
      query: () => ({
        url: '/getProductsEnabled',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: [ { type: 'Product', id: 'LIST' } ],
      keepUnusedDataFor: 36,
      pollInterval: 36000,
    }),
    getProductById: builder.query({
      query: (id) => ({
        url: `/getProductsAdmin/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getAllOrders: builder.query({
      query: () => ({
        url: '/getOrdersAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getOrdersById: builder.query({
      query: (id) => ({
        url: `/getOrdersAdmin/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getAllContacts: builder.query({
      query: () => ({
        url: '/getContactsAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getContactsById: builder.query({
      query: (id) => ({
        url: `/getContactsAdmin/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getCategoryList: builder.query({
      query: () => ({
        url: '/getProductsLanding',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getBrandsList: builder.query({
      query: () => ({
        url: '/getBrandsLanding',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getBusinessTypeList: builder.query({
      query: () => ({
        url: '/getBusinessTypeLanding',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getSpecializationList: builder.query({
      query: () => ({
        url: '/getSpecializationLanding',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getProductsAdminSearch: builder.query({
      query: () => ({
        url: '/getProductsAdminSearch',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getProductsAdminSearchInput: builder.query({
      query: (name) => ({
        url: '/getProductsAdminSearchByName',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          name
        }
      }),
    }),
    getOrderItemsByTradeName: builder.query({
      query: (query) => ({
        url: '/getOrdersUniqueAdminByTradeName',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: query || '',
        },
      }),
    }),
    getFacturasByClient: builder.query({
      query: (query) => ({
        url: `/getFacturasByClientAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: query.tradeName,
        },
      }),
      transformResponse: (response) => response.reverse(),
      providesTags: ['History'],
    }),
    getOrdersByTradeName: builder.query({
      query: (tradeName) => ({
        url: `/getOrdersAdminByTradeName?tradeName=${tradeName}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      transformResponse: (response) => response.reverse(),
    }),
    getProductsBySkus: builder.query({
      query: (query) => ({
        url: '/getProductsBySku',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          skus: query.skus || '',
        },
      }),
      providesTags: ['Products'],
    }),
    getClientPersonalSales: builder.query({
      query: (tradeName) => ({
        url: `/getClientPersonalSales?tradeName=${tradeName}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getContactClientByTradeName: builder.query({
      query: (tradeName) => ({
        url: `/getContactClientByTradeName?tradeName=${tradeName}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getBuisnessContactByTradeName: builder.query({
      query: (tradeName) => ({
        url: `/getBuisnessContactByTradeName?tradeName=${tradeName}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    
    createNewOrder: builder.mutation({
      query: (body) =>  ({
        url: './createOrderAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body
      })
    }),
    createNewOrderBrowser: builder.mutation({
      query: (body) =>  ({
        url: './createOrderAdminBrowser',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body
      })
    }),
    updateOrderItemsAdmin: builder.mutation({
      query: (body) =>  ({
        url: './updateOrderItemsAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body
      })
    }),
    updateDescriptionOrder: builder.mutation({
      query: (body) =>  ({
        url: './changeDescriptionOrder',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body
      })
    }),
    updateStatusOrderAdmin: builder.mutation({
      query: (body) =>  ({
        url: './updateStatusOrderAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body
      })
    }),
    updateCurrentCartContact: builder.mutation({
      query: (body) =>  ({
        url: './updateCurrentCartContact',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body
      })
    }),
    addClientPersonalPhones: builder.mutation({
      query: (body) =>  ({
        url: './addClientPersonalPhones',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body
      })
    }),
    
  }),
})

