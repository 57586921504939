import React, { useState } from 'react'
import './TemplateItemEditCounter.scss'

const TemplateItemEditCounter = ({item, handleInputChange, handleInputDecrement, handleInputIncrement}) => {
  const [quantity, setQuantity] = useState(item.quantity);

  return (
    <div className='TemplateItemEditCounter'>
      <div 
        className='TemplateItemEditCounter__decrement'
        onClick={() => {
          handleInputDecrement(item.quantity-1)
          setQuantity(quantity-1)
        }}
      >-</div>
      <div className='TemplateItemEditCounter__quantity'>
        <input 
          onChange={(event) => {
            const value = event.target.value !== '' ? parseFloat(event.target.value).toFixed(0) : 0;
            handleInputChange(value)
            setQuantity(value)
          }}
          onBlur={(event) => {
            if (event.target.value === '0') {
              handleInputDecrement(item.quantity-1)
            }
          }}
          type="number"
          value={quantity}
          name="quantity"
          data-field="quantity"
          />
      </div>
      <div 
        className='TemplateItemEditCounter__increment'
        onClick={() => {
          handleInputIncrement(item.quantity+1)
          setQuantity(quantity+1)
        }}
      >+</div>
    </div>
  )
}

export default TemplateItemEditCounter