import React, { useState, useEffect, useMemo } from 'react'

import './StaticOrder.scss'
import { useLocation } from 'react-router'
import { mainApi } from '../../../store/services/mainApi'
import StyledBox from '../StyledBox/StyledBox'
import { DataGrid, GridPagination, GridToolbar, GridToolbarContainer, GridToolbarExport, useGridApiContext } from '@mui/x-data-grid'
import { ReactComponent as Like } from '../../components/assets/images/Like.svg'
import { ReactComponent as InProgress } from '../../components/assets/images/inProgress.svg'
import { ReactComponent as Production } from '../../components/assets/images/cap.svg'
import { ReactComponent as Delivery } from '../../components/assets/images/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../components/assets/images/Congelador.svg'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Button } from '@mui/material'
import swipeLeft from '../../components/assets/images/Person-line.svg'

import { CircularProgress, useMediaQuery } from '@mui/material'
import FastOrder from '../StaticPage/FastOrder/FastOrder'
import { wrap } from 'lodash'

export const StaticOrder = ({ orderId }) => {
  const mobile = useMediaQuery('(max-width: 768px)')
  const location = useLocation()
  const {
    data: order,
    isLoading,
    isError,
  } = mainApi.useGetOrdersByIdQuery(orderId, {
    pollingInterval: 50000,
  })

  const [deliveryDate, setDeliveryDate] = useState('')
  const [creationDate, setCreationDate] = useState('')
  const [creationTime, setCreationTime] = useState('')
  const [items, setItems] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    console.log(orderId)
    if (order) {
      console.log(order)
      // Устанавливаем deliveryDate после получения order
      setDeliveryDate(order.deliveryDate ? order.deliveryDate : '')

      // Устанавливаем items после получения order
      setItems(order.items)

      // Устанавливаем дату и время создания после получения order
      const date = new Date(order.date)
      const localeDate = date.toLocaleDateString() // Получаем локализованную дату
      const localeTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) // Получаем локализованное время

      setCreationDate(localeDate)
      setCreationTime(localeTime)

      // Устанавливаем rows после получения order
      setRows(order.items)
    }
  }, [order])

  const statusBackground = (status) => {
    switch (status) {
      case 'Ready':
        return <Like width={20} height={20} />
      case 'PROCESSING':
        return <InProgress width={20} height={20} />
      case 'NOT AVAILABLE':
        return '⛔'
      case 'CHECKING':
        return '👁️'
      case 'PRODUCTION':
        return <Production width={20} height={20} /> //Production
      case 'SHOPPING':
        return <Delivery fill='#6D26E1' width={20} height={20} />
      case 'CONGEDOR':
        return <CONGEDOR width={20} height={20} />
      case 'DEVOLUCION':
        return '🔙'
      case 'CONFIRMAR':
        return '👍'
      default:
        return null
    }
  }

  const [openDialog, setOpenDialog] = useState(false)
  const [statusName, setStatusName] = useState('')

  const handleCellClick = (params, event) => {
    if (params.field === 'status') {
      setStatusName(params.row.status.name) // предполагается, что статус находится в params.row.status
      setOpenDialog(true)
    }
  }

  const CustomFooter = () => {
    const apiRef = useGridApiContext()

    return (
      <GridToolbarContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* <GridToolbarExport /> */}
        {/* Здесь можно добавить любой кастомный контент */}
        <Box>
          <img src={swipeLeft} alt='swipe' width={30} height={30} />
        </Box>
        <Box>
          <GridPagination />
        </Box>
        {/* Кнопки переключения страниц будут добавлены автоматически */}
      </GridToolbarContainer>
    )
  }

  const columns = [
    {
      field: 'img',
      headerName: 'Image',
      width: 70,
      sortable: false, // Отключает сортировку для колонки
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      renderHeader: () => {
        return <p style={{ margin: '0 auto' }}>Image</p>
      },
      renderCell: (params) => {
        return <img src={params.value} alt='' style={{ width: '50px', height: '50px' }} />
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 70,
      sortable: false,
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      renderCell: (params) => (
        <p title={params.value.name} style={{ margin: '0 auto' }}>
          {statusBackground(params.value.name)}
        </p>
      ),
    },

    {
      field: 'currentUnit',
      headerName: 'Unit',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      width: 70,
      renderCell: (params) => <p>{params.value}</p>,
    },
    {
      field: 'quantity',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      headerName: 'Quantity',
      width: 80,
      renderCell: (params) => <p>{params.value}</p>,
    },

    {
      field: 'marketPriceCP',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      headerName: 'Price',
      width: 70,
      renderCell: (params) => <p>{params.value}€</p>,
    },
    {
      field: 'IVA',
      headerName: 'IVA',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      width: 50,
      renderCell: (params) => <p>{params.value ? params.value : 0}%</p>,
    },

    {
      field: 'total',
      headerName: 'Total',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      width: 80,
      renderCell: (params) => <p>{params.row ? (params.row.quantity * params.row.marketPriceCP).toFixed(2) : ''}€</p>,
    },
    {
      field: 'name',
      headerName: 'Name',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      width: 300,
      renderCell: (params) => (
        <p
          style={{
            maxWidth: '300px',
            overflow: 'hidden',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            // marginBottom: 0, // Убираем отступ снизу, если он есть
          }}
        >
          {params.value}
        </p>
      ),
    },
  ]

  const total = useMemo(() => {
    if (order && order.items) {
      if (order?.deliveryPrice) {
        return (
          order.deliveryPrice +
          order.items
            .map((item) => (item.marketPriceCP !== '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
            .reduce((acc, curr) => acc + curr, 0)
        )
      } else {
        return order.items
          .map((item) => (item.marketPriceCP !== '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
          .reduce((acc, curr) => acc + curr, 0)
      }
    }
    return 0 // return a default value if items is falsy
  }, [order])

  const IVA = useMemo(() => {
    let totalIva = 0
    if (order && order.items) {
      totalIva = order.items
        .map((item) => (item.IVA ? (item.IVA / 100) * (item.marketPriceCP * (item.quantity || 1)) : 0))
        .reduce((acc, curr) => acc + curr, 0) // Provide 0 as the initial value
    }
    console.log(totalIva, 'IVA')
    // if (order.items && order.items.length > 0) {
    //   totalIva = totalIva / order.items.length
    // }
    return totalIva ? totalIva : 0 // return a default value if items is falsy
  }, [order])

  const [isRepModal, setIsRepModal] = useState(false)
  console.log(order);

  return (
    <div className='staticOrder'>
      {!isLoading ? (
        isError ? (
          <p
            style={{
              color: 'red',
              margin: '50px auto',
              textAlign: 'center',
            }}
          >
            Order not found
          </p>
        ) : (
          <div
            style={{ width: '95%', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: mobile ? 'column' : 'row',
                gap: '20px',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <StyledBox defaultVisible={true} sx={{ width: 'max-content', minWidth: '300px', maxWidth: '100%' }}>
                <div className='orderInfo__box__item'>
                  <p>
                    ORDER: <span>{order?.idTucomida ? order.idTucomida : order?._id ? order?._id.slice(-6) : ''}</span>
                  </p>
                </div>

                <div className='orderInfo__box__item'>
                  <p>
                    PAYMENT TYPE:{' '}
                    <span>
                      {order?.paymentType !== '-'
                        ? parseFloat(order?.paymentType)
                          ? 'Сдача с ' + order?.paymentType + '€'
                          : order?.paymentType
                        : 'Без сдачи'}
                    </span>
                  </p>
                </div>
                <div className='orderInfo__box__item'>
                  <p>
                    CREATION:{' '}
                    <span>
                      {creationDate} {creationTime}
                    </span>
                  </p>
                </div>
                <div className='orderInfo__box__item'>
                  <p>
                    DELIVERY: <span>{deliveryDate ? deliveryDate : ''}</span>
                    <span>{deliveryDate && deliveryDate.includes(',') ? (deliveryDate.split(',')[1].slice(0, -6) > 12 ? '🌛' : '🌞') : ''}</span>
                  </p>
                </div>
                <div className='orderInfo__box__item'>
                  <p>
                    TOTAL ITEMS: <span>{items ? items.length : 0}</span>
                  </p>
                </div>
                <div className='orderInfo__box__item'>
                  <p>
                    COMMENT:<span>{order?.description ? order?.description : 'No comment'}</span>
                  </p>
                </div>
              </StyledBox>
              <StyledBox defaultVisible={true} sx={{ width: 'max-content', minWidth: '300px', maxWidth: '100%' }}>
                <div className='orderInfo__box__item'>
                  <p>
                    GOODS W/O TVA: <span>{(total - IVA - (order?.deliveryPrice ? +order?.deliveryPrice : 0)).toFixed(2)}€</span>
                  </p>
                </div>
                <div className='orderInfo__box__item'>
                  <p>
                    TVA: <span>{IVA.toFixed(2)}&euro;</span>
                  </p>
                </div>
                <div className='orderInfo__box__item'>
                  <p>
                    Delivery: <span>{order?.deliveryPrice ? order?.deliveryPrice : '0.00'}&euro;</span>
                  </p>
                </div>

                <div className='orderInfo__box__item'>
                  <p>
                    Total: <span>{total.toFixed(2)}€</span>
                  </p>
                </div>
                <div className='orderInfo__box__item'>
                  <p>
                    Phone: <span>{order?.userAcc?.phone}</span>
                  </p>
                </div>
                <div className='orderInfo__box__item'>
                  <p>
                    Name: <span>{order?.userAcc?.name}</span>
                  </p>
                </div>
              </StyledBox>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '10px 0',
              }}
            >
              <p>STATUS: </p>
              <p
                style={{
                  textTransform: 'capitalize',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: order?.status?.color ? order?.status?.color : '#fff',
                  border: '1px solid ' + (order?.status?.color ? order?.status?.color : '#fff'),
                  margin: '0 5px',
                }}
              >
                {order?.status.name ? order?.status.name : 'In progress'}
              </p>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <FastOrder contactID={location.pathname.split('/').pop()} tradeNames={order?.items.map((el) => el.sku) || []} 
                  sxBtn={{ width: '100%', padding: '5px 10px', color: '#fff', borderRadius: '10px', cursor: 'pointer', backgroundColor: '#5FC56D', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '16px', textWrap: 'nowrap' }}
                />
                <Button
                  size='small'
                  variant='contained'
                  sx={{ width: '100%', padding: '5px 10px', color: '#fff', borderRadius: '10px', cursor: 'pointer', backgroundColor: '#5FC56D', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '16px' }}
                  onClick={() => {
                    window.open(`order/${order?._id}`, '_blank')
                  }}
                >
                  COMPARTIR
                </Button>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <DataGrid
                autoHeight={true}
                sx={{
                  marginTop: '10px',
                  backgroundColor: '#fff',
                }}
                onCellClick={handleCellClick}
                // slots={{ toolbar: GridToolbar }}
                // disableColumnFilter
                // slotProps={{
                //   toolbar: {
                //     showQuickFilter: true,
                //   },
                // }}
                rows={rows}
                columns={columns}
                components={{
                  Footer: CustomFooter,
                }}
              />
            </div>
          </div>
        )
      ) : (
        <CircularProgress color='success' />
      )}
      {/* Диалоговое окно для отображения статуса */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Status: {statusName}</DialogTitle>
        {/* Здесь может быть дополнительный контент диалога */}
      </Dialog>
    </div>
  )
}
