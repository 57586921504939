import React, { useState } from 'react'
import './OrderElement.scss'
import ArrowIcon from '../../components/assets/images/ArrowDown_Black.svg'
import ItemOrderElement from './ItemOrderElement/ItemOrderElement'

const OrderElement = ({ item, totalData }) => {
  const [isOpen, setIsOpen] = useState(false)
  // console.log(totalData);

  return (
    <div className='OrderElement'>
      <div className='OrderElement__header' onClick={() => setIsOpen(!isOpen)}>
        <div className='OrderElement__header__info'>
          +{item.userAcc.phone} | {item.tradeName} | {item.userAcc.name}
        </div>
        <div className='OrderElement__header__stats'>
          <div className='OrderElement__header__stats__info'>
            <div>
              <h1>Total: </h1><p>{totalData.total.toFixed(2)}€/</p><span>{totalData.saleTotal.toFixed(2)}€</span>
            </div>
            <span>|</span>
            <div className='items'>
              <p>items: </p>
              <span>{item.items.length}</span>
            </div>
          </div>
          <div className='OrderElement__header__stats__closeBtn'>
            <img src={ArrowIcon} alt="arrow" style={{transform: isOpen ? 'rotate(180deg)' : ''}}/>
          </div>
        </div>
      </div>
      {isOpen && <>
        <hr />
        {item.items.map((item, index) => (
          <ItemOrderElement item={item} key={index} />
        ))}
      </>}
    </div>
  )
}

export default OrderElement