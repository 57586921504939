import React, { Component, useEffect } from 'react'
import  { useNavigate } from 'react-router-dom'
import Header from '../components/Header/Header';
import HeaderMobile from '../components/Header/HeaderMobile/HeaderMobile';
import PreviewFilter from '../components/Content/PreviewCategories/PreviewFilter/PreviewFilter';
import PreviewNav from '../components/Content/PreviewCategories/PreviewNav/PreviewNav';
import PreviewNavMobile from '../components/Content/PreviewCategories/PreviewNav/PreviewNavMobile/PreviewNavMobile';
import ErrorPage from './ErrorPage';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    // You can also log the error here
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='App__page'> 
          <Header />
          <HeaderMobile/>
          <PreviewFilter />
          <PreviewNav />
          <PreviewNavMobile/>
          <ErrorPage/>
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorHandler