import React, { useState, useEffect, useId } from 'react'
import './ItemCounterModal.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setAllItemsCart, setCartElemCurrentUnit, setCartElemQuantityIncrement, setCartElemQuantityValue } from '../../../../../store/slices/allItemsCart'
import { Icon } from '@iconify/react/dist/iconify.js'
import { setItemsCart } from '../../../../../store/slices/itemsCart'
import useFindInCart from '../../../../../hooks/useFindInCart'

const ItemCounterModal = ({ item, keyId, grammMode }) => {
  // console.log(item?.unit);
  const [cardUnits, setCardUnits] = useState(item?.unit?.split(', ') === undefined | item?.unit?.split(', ').length === 0 ? ['und'] : item?.unit?.split(', '))
  // console.log(cardUnits);
  const dispatch = useDispatch()
  const allItemsCart = useSelector((state) => state.allItemsCart.allItemsCart)
  const inCart = useFindInCart(item)

  
  const [showQuantity, setShowQuantity] = useState(0)
  const [itemSettings, setItemSettings] = useState({quantity: parseFloat(inCart?.quantity) || 0, unit: inCart?.currentUnit || cardUnits[0]})


  useEffect(() => {
    setItemSettings({quantity: parseFloat(inCart?.quantity) || 0, unit: inCart?.currentUnit || cardUnits[0]})
    setShowQuantity(grammMode ? (parseFloat((inCart?.quantity ? inCart.quantity*1000 : 0))) : (parseFloat((inCart?.quantity ? inCart.quantity >= 1 ? inCart.quantity : 1 : 0))))
  }, [inCart, grammMode])
  
  const handleChangeUnit = (unit) => {
    setItemSettings({...itemSettings, unit: unit})
    dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku }, value: unit }))
  }
  const handleSetCount = (value) => {
    setShowQuantity((parseFloat(value)).toFixed(0))
    if (grammMode) {
      setItemSettings({...itemSettings, quantity: parseFloat(value)/1000})
      !inCart && (parseFloat(value)/1000) > 0 && dispatch(setAllItemsCart(item))
      inCart && (parseFloat(value)/1000) <= 0 && dispatch(setAllItemsCart(item))
      dispatch(setCartElemQuantityValue({ value: parseFloat(value)/1000, elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    } else {
      setItemSettings({...itemSettings, quantity: parseFloat(value)})
      !inCart && parseFloat(value) > 0 && dispatch(setAllItemsCart(item))
      inCart && parseFloat(value) <= 0 && dispatch(setAllItemsCart(item))
      dispatch(setCartElemQuantityValue({ value: parseFloat(value), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    }}
  const handleDecrement = () => {
    if (grammMode) {
      if (itemSettings.quantity > 0) {
        setItemSettings({...itemSettings, quantity: parseFloat(itemSettings.quantity - 0.1)})
      }
      if (itemSettings.quantity <= 0.1) {
        setItemSettings({quantity: 0, unit: item?.unit})
        inCart && dispatch(setAllItemsCart(item))
      }
      dispatch(setCartElemQuantityValue({ value: parseFloat((itemSettings.quantity-0.1).toFixed(1)), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    } else {
      if (itemSettings.quantity >= 1) {
        setItemSettings({...itemSettings, quantity: parseFloat(itemSettings.quantity - 1)})
      }
      if (itemSettings.quantity <= 1) {
        setItemSettings({quantity: 0, unit: item?.unit})
        inCart && dispatch(setAllItemsCart(item))
      }
      dispatch(setCartElemQuantityValue({ value: parseFloat((itemSettings.quantity-1).toFixed(1)), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    }
  }
  const handleIncrement = () => {
    if (grammMode) {
      if (!inCart) {
        dispatch(setAllItemsCart(item))
        dispatch(setCartElemQuantityValue({ value: 0.1.toFixed(1), elem: item }))
      }
      inCart && dispatch(setCartElemQuantityValue({ value: parseFloat(itemSettings.quantity+0.1).toFixed(1), elem: item }))
      
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    } else {
      !inCart && dispatch(setAllItemsCart(item))
      inCart && dispatch(setCartElemQuantityIncrement({ sku: item?.sku }))  
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    }
  }

  return (
    <div className='ItemCounterModal'>
      <div className='ItemCounterModal__select'>
        <div className='ItemCounterModal__select__controller'>
          <div 
            onClick={() => {handleDecrement()}}
            className='ItemCounterModal__select__controller__button'>
              <Icon icon="akar-icons:minus" />
            </div>
          <div className='ItemCounterModal__select__controller__quantity'>
            <input 
            type="number" 
            value={showQuantity}
            step={grammMode ? 100 : 1}
            onKeyPress={(event) => {return event.charCode >= 48 && event.charCode <= 57}}
            onKeyDown={(event) => {
              if (event.key==='.')
                {event.preventDefault()}
              }}
            onInput={(event) => {
              event.target.value = event.target.value.replace(/[^0-9]*/g,'')
            }}
            onChange={(e) => {handleSetCount(e.target.value)}}
            onBlur={(e) => {e.target.value == '' ? handleSetCount(0) : handleSetCount(e.target.value)}}
          />
          </div>
          <div 
            onClick={() => {handleIncrement()}}
            className='ItemCounterModal__select__controller__button'>
            <Icon icon="akar-icons:plus" />
          </div>
        </div>
        {<div className='ItemCounterModal__select__unit' key={'ItemCounterModal__select__unit__contener'}>
          {cardUnits.map((unit, index) => (
            <>{index>0 && <pre key={'ItemCounterModal__select__unit__contener'+index}> | </pre>}
            <button
              key={'ItemCounterModal__select__unit'+index}
              onClick={() => {handleChangeUnit(unit)}}
              style={{borderBottom: itemSettings.unit === unit ? '2px solid #fff' : '2px solid #5fc56e'}}
            >
              {unit == 'kg' & grammMode ? 'gr' : unit}
            </button></>
          ))}
        </div>}
      </div>
    </div>
  )
}

export default ItemCounterModal