import React, { useEffect, useState } from 'react'
import useSalePrice from './useSalePrice';

const useGetUpdateSalePrice = (salesData, isLoadingSales, userPhone) => {
  const [salesItemsPrice, setSalesItemsPrice] = useState([]);

  useEffect(() => {
    if (!isLoadingSales) {
      const salesItems = salesData?.salesItems?.map((el) => 
          {
            return {
              sku: el.sku,
              eventCurrentSalesQuantity: el.eventCurrentSalesQuantity,
              marketPriceCP: el.marketPriceCP,
              spoPrice: useSalePrice(el.eventCurrentSalesQuantity, el),
            }
          }
        )
      setSalesItemsPrice(salesItems);
    }
  }, [salesData, isLoadingSales]);
  
  
  return salesItemsPrice
}

export default useGetUpdateSalePrice