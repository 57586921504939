import React, { useEffect, useState } from 'react'
import useSalePrice from './useSalePrice';

const useCalcTotal = (salesData, isLoadingSales, userPhone) => {
  // console.log(salesData);
  const [userItemsPrice, setUserItemsPrice] = useState([]);

  useEffect(() => {
    if (!isLoadingSales) {
      const salesItems = salesData?.salesItems?.map((el) => 
          {
            return {
              sku: el.sku,
              eventCurrentSalesQuantity: el.eventCurrentSalesQuantity,
              marketPriceCP: el.marketPriceCP,
              spoPrice: useSalePrice(el.eventCurrentSalesQuantity, el),
            }
          }
        )
      const userCurrentOrders = salesData.currentOrders?.filter(el => el.userAcc.phone === userPhone)
      const totalPriceItems = userCurrentOrders?.map(el => el.items.reduce((acc, item) => acc + (item.marketPriceCP * item.quantity), 0))
      const totalSalePriceItems = userCurrentOrders?.map(el => el.items.reduce((acc, item) => acc + (salesItems.find(el => el.sku === item.sku).spoPrice * item.quantity), 0))

      const totlaPriceInfo = userCurrentOrders.map((el, index) => {
        return {
        total: totalPriceItems[index],
        saleTotal: totalSalePriceItems[index],
      }}) 
      setUserItemsPrice(totlaPriceInfo);
    }
  }, [salesData, isLoadingSales]);
  
  
  return userItemsPrice
}

export default useCalcTotal