import React from 'react'
import './SearchListItemCounter.scss'

const SearchListItemCounter = ({item, foundItem, addItem}) => {
  return (
    <div className='SearchListItemCounter'>
      {foundItem ? 
      <div className='SearchListItemCounter__wrapper'>
        <div className='SearchListItemCounter__wrapper__price'>{item.marketPriceCP}€</div>

        <div className='SearchListItemCounter__wrapper__removeBtn'>+</div>
      </div> :
      <div className='SearchListItemCounter__wrapper'>
        <div className='SearchListItemCounter__wrapper__price'>{item.marketPriceCP}€</div>
        <div 
          className='SearchListItemCounter__wrapper__addBtn'
          onClick={() => addItem()}
        >+</div>
      </div>}
    </div>
  )
}

export default SearchListItemCounter