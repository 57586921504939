import axios from 'axios';
import { useEffect } from 'react'
import { useParams } from 'react-router';

const useChangePageTitle = (dependens=[], setData) => {
  const tradeName = useParams().tradeName

  useEffect(() => {
    if (tradeName !== undefined) {
      const tradeId = async () => { 
        const responseLogin = await axios.get(`https://tpomobi.shop/getBuisnessContactByTradeName?tradeName=${tradeName}`)
        // console.log(responseLogin.data);
        document.title = `${responseLogin.data?.employee}`;
        const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
        favicon.rel = 'icon';
        favicon.href = `${responseLogin.data?.img}`;
        document.head.appendChild(favicon);
        if (setData) {
          setData(responseLogin.data);
        }
      }
      tradeId()
    } 
  }, [dependens]);
}

export default useChangePageTitle