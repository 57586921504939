import React, { useEffect, useState } from 'react'
import './TemplateItemEdit.scss'
import CloseElem from '../../../assets/images/closeElem.svg'
import TemplateItemEditCounter from './TemplateItemEditCounter/TemplateItemEditCounter';

const TemplateItemEdit = ({item, setEditedItems, editedItems, hideImage}) => {
  const foundItem = editedItems.find((el) => el.sku === item.sku);
  const [dataItem, setDataItem] = useState({quantity: foundItem.quantity, marketPriceCP: foundItem.marketPriceCP});

  const handleInputChange = (eventValue) => {
    const value = parseFloat(eventValue);
    const sku = item.sku;

    if (sku) {
      const item = editedItems.find((item) => item.sku === sku);
      if (item) {
        const newItem = { ...item, quantity: value === '' ? 0 : parseFloat(value) };
        setDataItem({ ...dataItem, quantity: parseFloat(value) });
        const filterItems = editedItems?.filter((item) => item.sku !== sku);
        const array = filterItems.concat(newItem).sort((a, b) => a.name.localeCompare(b.name));
        setEditedItems(array);
      }
    }
  };

  const handleInputIncrement = (value) => {
    const sku = item.sku;

    if (sku) {
      const item = editedItems.find((item) => item.sku === sku);
      if (item) {
        const newItem = { ...item, quantity: value === '' ? 0 : parseFloat(value) };
        setDataItem({ ...dataItem, quantity: value });
        const filterItems = editedItems?.filter((item) => item.sku !== sku);
        const array = filterItems.concat(newItem).sort((a, b) => a.name.localeCompare(b.name));
        setEditedItems(array);
      }
    }
  };
  
  const handleInputDecrement = (value) => {
    const sku = item.sku;

    if (value > 0) {
      if (sku) {
        const item = editedItems.find((item) => item.sku === sku);
        if (item) {
          const newItem = { ...item, quantity: value === '' ? 0 : parseFloat(value) };
          setDataItem({ ...dataItem, quantity: value });
          const filterItems = editedItems?.filter((item) => item.sku !== sku);
          const array = filterItems.concat(newItem).sort((a, b) => a.name.localeCompare(b.name));
          setEditedItems(array);
        }
      }
    } else {
      setEditedItems(editedItems => editedItems?.filter(el => el.sku !== item.sku))
    }
  };
  

  return (
    <div className='TemplateItemEdit'>
      <div className='TemplateItemEdit__data'>
        {!hideImage && 
        <div className="TemplateItemEdit__data__img">
          <img src={item.img} alt="" />
        </div>}
        <div className="TemplateItemEdit__data__title">
          {item.name}
        </div>
      </div>

      <div className='TemplateItemEdit__dataParams'>
          <div className='TemplateItemEdit__dataParams__total'>
            {(item.quantity*item.marketPriceCP).toFixed(2)}€
          </div>
          <TemplateItemEditCounter 
            item={item} 
            handleInputIncrement={handleInputIncrement}  
            handleInputChange={handleInputChange}
            handleInputDecrement={handleInputDecrement}
          />
          <div 
            className='TemplateItemEdit__dataParams__deleteBtn'
            onClick={() => setEditedItems(editedItems => editedItems?.filter(el => el.sku !== item.sku))}
          >
            +
          </div>
      </div>
    </div>
  )
}

export default TemplateItemEdit