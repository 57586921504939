import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import unitEqual from '../../../../constants/unitEqual';
import useFindItemInSaleCart from '../../../../hooks/SalePageHooks/useFindItemInSaleCart';
import useFindItemByUnit from '../../../../hooks/useFindItemByUnit';

const SaleTotalCartItem = ({item, list}) => {
  const dispatch = useDispatch();
  const grammMode = useSelector((state) => state.contextUI.grammMode)
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)
  const inCart = useFindItemInSaleCart(item)

  return (
    <div className='cartModal__body__item' key={'table'+item?.title+item?.sku}>
      <div className='cartModal__body__item__row'>
        <img src={item?.img} alt="" style={{ borderRadius: '10px'}}/>
        <div className='cartModal__body__item__row__info'>
          {item?.name}
        </div>
        {item?.pdt &&
          <div className='contentCard__body__subtitle__info dias' style={{width: 'fit-content', textWrap: 'NoWrap'}}>{item?.pdt}</div>}
        {item?.spoPrice ? <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
        <span style={{textDecoration: 'line-through'}}>
          {ivaSwitch ? (item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' : 
          ((item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(100+parseInt(item?.IVACP))*100).toFixed(2) +'€'
          }
        </span>
        <span style={{color: '#5FC56E'}}>
          {ivaSwitch ? (item?.spoPrice* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' : 
          ((item?.spoPrice* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(100+parseInt(item?.IVACP))*100).toFixed(2) +'€'
          }
        </span> </div> :
        <span>
          {ivaSwitch ? (item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' : 
          ((item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(100+parseInt(item?.IVACP))*100).toFixed(2) +'€'
          }
        </span>
        }
      </div>
      <div className='cartModal__body__item__row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
        <div style={{
          fontWeight: 400,
          fontSize: '14px',
          width: '100%',
        }}>
          quantity: {item.quantity}
        </div>
        <div 
        className='cartModal__body__item__row'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px', width: 'fit' }}
      >
        {unitEqual.find((el) => el.sku === item?.sku)?.und && ('und: '+unitEqual.find((el) => el.sku === item?.sku)?.und+" kg aprox ")
        }
        {unitEqual.find((el) => el.sku === item?.sku)?.caja && ('caja: '+unitEqual.find((el) => el.sku === item?.sku)?.caja+" kg aprox ")
        }
        {unitEqual.find((el) => el.sku === item?.sku)?.saco && ('saco: '+unitEqual.find((el) => el.sku === item?.sku)?.saco+" kg aprox ")
        }
      </div>
      </div>
      
    </div>
  )
}

export default SaleTotalCartItem