import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  teplateOrderItems: ['s'],
}

export const teplateOrderSlice = createSlice({
  name: 'teplateOrder',
  initialState,
  reducers: {
    setItemsTemplate: (state, action) => {
      state.teplateOrderItems = [...action.payload]
    },
    setAllItemsCart: (state, action) => {
      const filterArray = state.teplateOrderItems.map((elem) => elem.sku)
      if (!filterArray.includes(action.payload.sku)) {
        if (action.payload.quantity === 0) {
          state.teplateOrderItems = [...state.teplateOrderItems, { ...action.payload}]
        } else {
          state.teplateOrderItems = [...state.teplateOrderItems, action.payload]
        }
      } else {
        state.teplateOrderItems = state.teplateOrderItems.filter((item) => item.sku !== action.payload.sku)
      }
    },
    deleteCartElem: (state, action) => {
      state.teplateOrderItems = state.teplateOrderItems.filter((item) => item?.sku !== action.payload.sku)
    },
  },
})

export const {
  setItemsTemplate,
  deleteCartElem,
} = teplateOrderSlice.actions

export default teplateOrderSlice.reducer
