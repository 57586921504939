import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import CartSynch from './synchronaizers/CartSynh'
import AutoLogin from '../ModalWindows/LoginModal/AutoLogin/AutoLogin'
import TradeNameSynh from './synchronaizers/TradeNameSynh'

const AutoDataSynchronaizer = () => {
  //Cart synchronizator
  

  
  const itemsCart = useSelector((state) => state.itemsCart.itemsCart)
  useEffect(() => {
    localStorage.removeItem('itemsCart')
    localStorage.setItem('itemsCart', JSON.stringify(itemsCart))
  }, [itemsCart])
  
  const allItemsFavorite = useSelector((state) => state.allItemsFavorites.allItemsFavorites)
  useEffect(() => {
    localStorage.removeItem('allItemsFavorite')
    localStorage.setItem('allItemsFavorite', JSON.stringify(allItemsFavorite))
  }, [allItemsFavorite])
  return (
    <>
      <CartSynch/>
      <AutoLogin/>

    </>
  )
}

export default AutoDataSynchronaizer