import React from 'react'

const useSalePrice = (quantity, item) => {
  let price = item.marketPriceCP
  item.priceRange?.forEach(element => {
    if (quantity >= element.minQuantity) {
      price = element.price
    }
  });
  return price
}

export default useSalePrice