import { Checkbox } from '@mui/material'
import './SalePageElement.scss'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setItemModal } from '../../../store/slices/contextUI'
import SalePageCounter from '../SalePageCounter/SalePageCounter'
import ArrowSalePage from '../../components/assets/images/arrowSalePage.svg'
import ArrowGreenSalePage from '../../components/assets/images/arrowGreenSalePage.svg'
import useFindInSaleCart from '../../../hooks/SalePageHooks/useFindItemInSaleCart'
import useSalePrice from '../../../hooks/SalePageHooks/useSalePrice'

const SalePageElement = ({ item }) => {
  const dispatch = useDispatch()
  const inCartTest = useFindInSaleCart(item)
  const salePrice = useSalePrice(item.eventCurrentSalesQuantity+(inCartTest?.quantity?inCartTest.quantity:0), item)
  const allPersent = ((item.eventCurrentSalesQuantity+(inCartTest?.quantity?inCartTest.quantity:0)) / item.eventStockQuantity)
  
  return (
    <div className='SalePageElement' key={item.title+item._id}>
      <div className='SalePageElement__itemData'>
        <div className='SalePageElement__itemData__content'>
          <img src={item.img} alt="" style={{ borderRadius: '10px', height: '80px'}} onClick={() => {dispatch(setItemModal({item: item, isShow: true}))}}/>
          
        </div>
        <div className='SalePageElement__itemData__name'>
          {item.name}
        </div>
      </div>
      <div className='SalePageElement__bar'>
          <div className='SalePageElement__bar__start'>
            {item.marketPriceCP}€
          </div>
          <div className='SalePageElement__bar__progressbar'>
            
            <div className='SalePageElement__bar__progressbar__goal'>
              <div 
                className='SalePageElement__bar__progressbar__goal__weight'
                style={{left: `${((100) * allPersent)}%`}}
              >
                <div className='SalePageElement__bar__progressbar__goal__weight__counter'>
                  <SalePageCounter item={item} />
                </div>
                <div className='SalePageElement__bar__progressbar__goal__weight__arrow'>
                  <img src={ArrowSalePage} alt="" />
                </div>
              </div>
            </div>
            <div className='SalePageElement__bar__progressbar__slider'>
              <div 
                className='SalePageElement__bar__progressbar__slider__indicator'
                style={{width: `${((100) * allPersent)}%`,}}
              >
                {inCartTest?.quantity > 20 | item.eventCurrentSalesQuantity > 20 && item.eventCurrentSalesQuantity+(inCartTest?.quantity?inCartTest.quantity:0) +'kg'} 
              </div>
              <div className='SalePageElement__bar__progressbar__slider__target'>{item.eventStockQuantity}</div>
            </div>
            <div className='SalePageElement__bar__progressbar__goal'>
              <div 
                className='SalePageElement__bar__progressbar__goal__weight'
                style={{left: `${((100) * allPersent)}%`, marginTop: '-40px'}}
              >
                <div className='SalePageElement__bar__progressbar__goal__weight__arrow' style={{marginTop: '-5px'}}>
                  <img src={ArrowGreenSalePage} alt="" />
                </div>
                <div className='SalePageElement__bar__progressbar__goal__weight__counter' style={{color: '#5fc46e'}}>
                  {salePrice}€
                </div>
              </div>
            </div>
          </div>
          <div className='SalePageElement__bar__end'>
            {item.priceRange[item.priceRange.length-1].price}€
          </div>
      </div>
      <div className='SalePageElement__costInfo'>
        <div className='SalePageElement__costInfo__content'>
          <div className='SalePageElement__costInfo__content__text'>
            Precio corriente
          </div>
          <div className='SalePageElement__costInfo__content__price'>
            <span>{item.marketPriceCP}€/</span>{salePrice}€
          </div>
        </div>
        <div className='SalePageElement__costInfo__subContent'>
          <div className='SalePageElement__costInfo__subContent__text'>
            ahorro por kilo
          </div>
          <div className='SalePageElement__costInfo__subContent__price'>
            <div style={{color: '#d89b00'}}>{(item.marketPriceCP-salePrice).toFixed(2)}€</div>|
            <div style={{color: '#0070d8'}}>{((item.marketPriceCP-salePrice)/(item.marketPriceCP/100)).toFixed(2)}%</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalePageElement