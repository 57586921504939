import React from 'react'
import './Btn.scss'

const SaleBtn = ({ children, onClick=null, ref=null, sx={} }) => {
  return (
    <button className='saleBtn' onClick={onClick} ref={ref} style={sx}>
      {children}
    </button>
  )
}

export default SaleBtn