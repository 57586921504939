import React, { useEffect, useRef, useState } from 'react'
import './NavAlphaList.scss'

const NavAlphaList = ({ list, veiw, children, more, setMore }) => {

  const [scrollLetter, setScrollLetter] = useState(null);
  const [activeLetter, setActiveLater] = useState('A');
  const listRef = useRef(null);

  // console.log('list: '+list);

  const handleScroll = (e) => {
    const scrollPosition = e.target.scrollTop;
    const listItems = listRef.current.querySelectorAll('.NavAlphaList__list-item');
    
    listItems.forEach((item, index) => {
      if (item.offsetTop <= scrollPosition + item.clientHeight) {
        setScrollLetter(item.dataset.letter);
      }
    });
  };

  const scrollToLetter = (letter) => {
    if (letter !== '...') {setActiveLater(letter);
    const listItems = listRef.current.querySelectorAll('.NavAlphaList__list-item');
    let was = false;
    listItems.forEach((item) => {
      if (item.dataset.letter === letter && !was) {
        item.scrollIntoView({ behavior: 'smooth', block: 'start' });
        was = true;
      }
    });} else {
      if (setMore) {setMore(true)}
    }
  };
  const [scrollTop, setScrollTop] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    });
    return () => {window.removeEventListener("scroll", () => {});}
  }, []);

  // console.log(more);
  const MapArray = !more ? Array?.from(new Set(list?.map((item) => item?.name[0].toUpperCase())?.sort()?.concat('...'))) : Array?.from(new Set(list?.map((item) => item?.name[0].toUpperCase())?.sort()))

  return (
    <div className='NavAlphaList'>
      <div className='NavAlphaList__countainer'>
        
        <div className='NavAlphaList__countainer__listItems' onScroll={handleScroll} ref={listRef} style={{flexDirection: veiw !== 1 ? 'row' : 'column' }}>
          {children}
        </div>
      </div>


      <div className="NavAlphaList__scroll__menu">
        {scrollTop && MapArray?.map((letter) => (
          <div
            style={{color: letter == activeLetter ? '#5FC56E' : '#000'}}
            key={letter} 
            onClick={() => scrollToLetter(letter)}
          >{letter}</div>
        ))}
      </div>
    </div>
  )
}

export default NavAlphaList