import React, { useEffect, useState } from 'react'

const SaleTimer = ({salesData, isLoadingSales}) => {  
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    if (time<=0) {
      return '0:00'
    }

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const [eventStatus, setEventStatus] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  
  const convertTime = () => {
    const startDate = new Date(salesData?.timeStart);
    const endDate = new Date(salesData?.timeEnd);
    
    const startHours = startDate.getHours().toString().padStart(2, '0');
    const startMinutes = startDate.getMinutes().toString().padStart(2, '0');
    const startMonth = (startDate.getMonth() + 1).toString().padStart(2, '0');
    const startDay = startDate.getDate().toString().padStart(2, '0');
    const startYear = startDate.getFullYear().toString();
    
    const endHours = endDate.getHours().toString().padStart(2, '0');
    const endMinutes = endDate.getMinutes().toString().padStart(2, '0');
    
    return {
      start: `Event starts at ${startHours}:${startMinutes} on ${startMonth}/${startDay}/${startYear}`,
      end: `Event ends at ${endHours}:${endMinutes} on ${startMonth}/${startDay}/${startYear}`
    }
  }
  
  useEffect(() => {
    if (timeLeft <= 0) {
      setEventStatus(false);
    } else {
      setEventStatus(true);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (!isLoadingSales) {
      const interval = setInterval(() => {  
        const date = new Date()
        setTimeLeft((salesData?.timeEnd - date.getTime()) / 1000);
        if (timeLeft < 0) {
        setEventStatus(false);
        clearInterval(interval);
        setTimeLeft(0)
        }
        
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isLoadingSales]);


  return (
    <div className='SaleMyOrders__headData__timer'>
      <p>{convertTime().start}</p>
      <p>{convertTime().end}</p>
      
      <div className='clock'>
        {formatTime(timeLeft.toFixed(0))}
      </div>
    </div>
  )
}

export default SaleTimer