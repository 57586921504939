import { createBrowserRouter, Navigate } from 'react-router-dom'
import React from 'react'
import CategoriesPage from './pages/components/Content/Categories/CategoriesPage'
import ComplitedPayCart from './pages/components/Orders/Complited/ComplitedPayCart'
import Header from './pages/components/Header/Header'
import SectionSlider from './pages/components/Content/PreviewCategories/PreviewSection/SectionSlider/SectionSlider'
import SearchPage from './pages/components/Content/SearchPage/SearchPage'
import PreviewFilter from './pages/components/Content/PreviewCategories/PreviewFilter/PreviewFilter'
import PreviewNav from './pages/components/Content/PreviewCategories/PreviewNav/PreviewNav'
import PreviewAll from './pages/components/Content/PreviewCategories/PreviewAll/PreviewAll'
import OrderPage from './pages/components/Account/Profile/MainSection/OrderPage/OrderPage'
import PreviewSection from './pages/components/Content/PreviewCategories/PreviewSection/PreviewSection'
import HeaderMobile from './pages/components/Header/HeaderMobile/HeaderMobile'
import PreviewNavMobile from './pages/components/Content/PreviewCategories/PreviewNav/PreviewNavMobile/PreviewNavMobile'
import ProfileMobile from './pages/components/Account/Profile/ProfileMobile/ProfileMobile'
import PreviewList from './pages/components/Content/PreviewCategories/PreviewList/PreviewList'
import FilterModal from './pages/components/ModalWindows/FilterModal/FilterModal'
import ItemPage from './pages/components/ItemPage/ItemPage'
import ProfileHome from './pages/components/Account/Profile/ProfileHome/ProfileHome'
import ProfileInfo from './pages/components/Account/Profile/ProfileInfo/ProfileInfo'
import ProfileOrders from './pages/components/Account/Profile/ProfileOrders/ProfileOrders'
import ProfileLanguages from './pages/components/Account/Profile/ProfileLanguages/ProfileLanguages'
import DatosPage from './pages/components/Account/Profile/ProfileMobile/DatosPage/DatosPage'
import OrderPageMobile from './pages/components/Account/Profile/ProfileMobile/OrderPageMobile/OrderPageMobile'
import NewOrderPage from './pages/components/NewOrderPage/NewOrderPage'
import SpecializationPage from './pages/SpecializationPage/SpecializationPage'
import SpecializationCategoryPage from './pages/SpecializationCategoryPage/SpecializationCategoryPage'
import ToTopBtn from './pages/components/Content/ToTopBtn/ToTopBtn'
import ShadowPage from './pages/ShadowPage/ShadowPage'
import HomePage from './pages/HomePage/HomePage'
import HomeTab from './pages/HomeTab/HomeTab'
import AllTab from './pages/AllTab/AllTab'
import StaticPage from './pages/ProfilePage/StaticPage/StaticPage'
import SalePage from './pages/SalePage/SalePage'
import { StaticOrder } from './pages/ProfilePage/StaticOrder/StaticOrder'
import { StaticOrderFP } from './pages/ProfilePage/StaticOrder/StaticOrderFP/StaticOrderFP'
import SaleNewOrderPage from './pages/SaleNewOrderPage/SaleNewOrderPage.jsx'
import ErrorPage from './pages/ErrorPage/ErrorPage.jsx'
import SaleMyOrders from './pages/SaleMyOrders/SaleMyOrders.jsx'

export const routerDeleverItems = createBrowserRouter([
  {
    path: '*',
    element: <Navigate to={'/home'} />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/home',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <HomePage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/home/:tab',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <HomeTab />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/home/:mod/:tab',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <AllTab />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/searchPage',
    element: (
      <div className='App__container' style={{ display: 'flex', flexDirection: 'column' }}>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SearchPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/searchPage/:tradeName',
    element: (
      <div className='App__container' style={{ display: 'flex', flexDirection: 'column' }}>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SearchPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/categories',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <PreviewList />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/categories/:categoryType',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <FilterModal />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <PreviewAll />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/categories/:categoryType/:categoryName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <CategoriesPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/categories/:categoryType/:categoryName/:tradeName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <CategoriesPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/specialization/:specializationName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SpecializationPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/specialization/:specializationName/:tradeName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SpecializationPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/specialization/:specializationName/:categoryName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SpecializationCategoryPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/specialization/:specializationName/:categoryName/:tradeName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SpecializationCategoryPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile/home',
    element: (
      <div className='App__container' style={{ display: 'flex', flexDirection: 'column' }}>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ProfileHome />
        <HomePage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile/info',
    element: (
      <div className='App__container' style={{ display: 'flex', flexDirection: 'column' }}>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ProfileInfo />
        <div className='DatosPageMobile'>
          <DatosPage />
        </div>
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile/orders',
    element: (
      <div className='App__container' style={{ display: 'flex', flexDirection: 'column' }}>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ProfileOrders />
        <OrderPageMobile />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile/languages',
    element: (
      <div className='App__container' style={{ display: 'flex', flexDirection: 'column' }}>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ProfileLanguages />
        <ProfileMobile />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/order/:orderId',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <OrderPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/item/:id',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ItemPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/newOrder',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <NewOrderPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/newOrder/:tradeName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <NewOrderPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/newOrder/:platform/:tradeName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <NewOrderPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/orderComplited',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ComplitedPayCart />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/orderComplited/:tradeName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ComplitedPayCart />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/orderComplited/:platform/:tradeName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ComplitedPayCart />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/category/:tradeName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ShadowPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/category/:platform/:tradeName',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ShadowPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/group',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SalePage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/group/newOrder',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SaleNewOrderPage />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/group/myOrders',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <SaleMyOrders />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/account/:tradeName',
    element: <StaticPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/account/facturas/:tradeName',
    element: <StaticPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/account/order/:id',
    element: (
      // <StaticOrder/>
      <StaticOrderFP />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/client/static/order/:id',
    element: <StaticOrderFP />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/error',
    element: (
      <div className='App__page'>
        <ToTopBtn />
        <Header />
        <HeaderMobile />
        <PreviewFilter />
        <PreviewNav />
        <PreviewNavMobile />
        <ErrorPage />
      </div>
    ),
  },
])
