import { useSelector } from 'react-redux';

const useFindItemInSaleCart = (item) => {
  const convertedCart = useSelector((state) => state.saleCart.saleCart);
  return convertedCart?.find((items) => {
    return items?.sku === item?.sku
  })

}

export default useFindItemInSaleCart