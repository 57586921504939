import React, { useEffect, useState } from 'react'
import './AutoLogin.scss'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setUserAcc } from '../../../../../store/slices/accountData'
import { setIsLogin, setSynhronaizersCart } from '../../../../../store/slices/contextUI'
import { setItemsCart } from '../../../../../store/slices/allItemsCart'

const AutoLogin = () => {
  const userAcc = useSelector((state) => state.accountData.userAcc);
  const dispatch = useDispatch()


  const initLogin = async () => {
    const userData = localStorage.getItem('userData') !== null ? JSON.parse(localStorage.getItem('userData')) : null
    if (userData) {
    const currentDate = new Date();
    if (currentDate > userData.refreshLogin) {
      localStorage.setItem('userData', JSON.stringify({...userData,
        refreshLogin: null,
    }))
    } else {
    
    let informData =  {
        phone: userData.phone,
        password: userData.password,
    };
    const responseLogin = await axios.post('https://tpomobi.shop/acceptLoginAcc', {data: informData})
    dispatch(setUserAcc(responseLogin.data))
    dispatch(setIsLogin(responseLogin.data !== '' ? true : false))
    if (responseLogin.data.currentCart) {
      dispatch(setSynhronaizersCart(false))
      dispatch(setItemsCart(responseLogin.data.currentCart))
      dispatch(setSynhronaizersCart(true))
    }
    }}
  }

  useEffect(() => {
    initLogin()
  }, [])




}

export default AutoLogin