import React from "react"
import './TemplateItem.scss'

const TemplateItem = ({item, hideImage}) => {
  
  return (
    <div className='TemplateItem' key={item.sku}>
      {!hideImage && 
      <div className="TemplateItem__img">
        <img src={item.img} alt="" />
      </div>}
      <div className="TemplateItem__info">
        <div className="TemplateItem__info__title">
          {item.name}
        </div>
        <div className="TemplateItem__info__params">
          <div>Quantity: {item.quantity}</div> <div>|</div>
          <div>Price: {(item.marketPriceCP*item.quantity).toFixed(2)}€</div>
        </div>
      </div>
    </div>)
}

export default TemplateItem