import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  saleCart: [],
}

export const saleCartSlice = createSlice({
  name: 'saleCart',
  initialState,
  reducers: {
    setItemsSaleCart: (state, action) => {
      state.saleCart = [...action.payload]
    },
    setClearSaleCart: (state, action) => {
      state.saleCart = []
    },
    setAllItemsSaleCart: (state, action) => {
      const filterArray = state.saleCart.map((elem) => elem.sku)
      if (!filterArray.includes(action.payload.sku)) {
        if (action.payload.quantity === 0) {
          state.saleCart = [...state.saleCart, { ...action.payload, quantity: 1, quantityOrder: 1, timestamp: new Date().getTime(), }]
        } else {
          state.saleCart = [...state.saleCart, action.payload]
        }
      } else {
        state.saleCart = state.saleCart.filter((item) => item.sku !== action.payload.sku)
      }
    },
    setSaleCartElemQuantityIncrement: (state, action) => {
      const itemIndex = state.saleCart.findIndex((item) => item.sku === action.payload.sku)
      if (itemIndex >= 0) {
        state.saleCart[itemIndex].quantity = parseFloat(state.saleCart[itemIndex].quantity)
          ? parseFloat(state.saleCart[itemIndex].quantity) + 1
          : 1
      }
      if (itemIndex >= 0) {
        state.saleCart[itemIndex].quantityOrder = parseFloat(state.saleCart[itemIndex].quantityOrder)
          ? parseFloat(state.saleCart[itemIndex].quantityOrder) + 1
          : 1
      }
      if (itemIndex >= 0 && state.saleCart[itemIndex].sku === action.payload.sku) {
        const item = {...state.saleCart[itemIndex]}
        state.saleCart = [...state.saleCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date().getTime()}, ]
      }
    },
    setSaleCartElemQuantityDecrement: (state, action) => {
      const itemIndex = state.saleCart.findIndex((item) => item?.sku === action.payload.sku)
      if (itemIndex >= 0) {
        state.saleCart[itemIndex].quantity = parseFloat(state.saleCart[itemIndex].quantity)
          ? parseFloat(state.saleCart[itemIndex].quantity) - 1
          : 0
          if (state.saleCart[itemIndex].sku === action.payload.sku) {
            const item = {...state.saleCart[itemIndex]}
            state.saleCart = [...state.saleCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date().getTime()}, ]
          }
      }
      if (parseFloat(state.saleCart[itemIndex].quantity) <= 0) {
        state.saleCart[itemIndex].quantity = 0
      }
      if (itemIndex >= 0) {
        state.saleCart[itemIndex].quantityOrder = parseFloat(state.saleCart[itemIndex].quantityOrder)
          ? parseFloat(state.saleCart[itemIndex].quantityOrder) - 1
          : 0
      }
      if (parseFloat(state.saleCart[itemIndex].quantityOrder) <= 0) {
        state.saleCart[itemIndex].quantityOrder = 0
      }
    },
    setSaleCartElemQuantityValue: (state, action) => {
      const itemIndex = state.saleCart.findIndex((item) => item?.sku === action.payload.elem?.sku)
      if (itemIndex >= 0) {
        state.saleCart[itemIndex].quantity = parseFloat(action.payload.value)
      }
      if (itemIndex >= 0 && state.saleCart[itemIndex].sku === action.payload.sku) {
        const item = {...state.saleCart[itemIndex]}
        state.saleCart = [...state.saleCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date().getTime()}, ]
      }
      if (itemIndex >= 0) {
        state.saleCart[itemIndex].quantityOrder = parseFloat(action.payload.value)
      }
    },
    setSaleCartElemQuantityOrderValue: (state, action) => {
      const itemIndex = state.saleCart.findIndex((item) => item?.sku === action.payload.elem?.sku)
      if (itemIndex >= 0) {
        state.saleCart[itemIndex].quantityOrder = action.payload.quantityOrder
      }
      if (itemIndex >= 0 && state.saleCart[itemIndex].sku === action.payload.sku) {
        const item = {...state.saleCart[itemIndex]}
        state.saleCart = [...state.saleCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date().getTime()}, ]
      }
    },
    setSaleCartElemCurrentUnit: (state, action) => {
      const itemIndex = state.saleCart.findIndex((item) => item?.sku === action.payload.elem.sku)
      if (itemIndex >= 0) {
        state.saleCart[itemIndex].currentUnit = action.payload.value
      }
      if (itemIndex >= 0 && state.saleCart[itemIndex].sku === action.payload.sku) {
        const item = {...state.saleCart[itemIndex]}
        state.saleCart = [...state.saleCart.filter((item) => item.sku !== action.payload.sku), {...item, timestamp: new Date.getTime()}, ]
      }
    },
    deleteSaleCartElem: (state, action) => {
      state.saleCart = state.saleCart.filter((item) => item?.sku !== action.payload.sku)
    },
  },
})

export const {
  setClearSaleCart,
  setItemsSaleCart,
  deleteSaleCartElem,
  setAllItemsSaleCart,
  setSaleCartElemCurrentUnit,
  setSaleCartElemQuantityDecrement,
  setSaleCartElemQuantityIncrement,
  setSaleCartElemQuantityValue,
  setSaleCartElemQuantityOrderValue
} = saleCartSlice.actions

export default saleCartSlice.reducer
