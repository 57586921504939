import React from 'react'
import './PreviewNavMobile.scss'
import PreviewMenu from '../PreviewMenu/PreviewMenu'
import PreviewMoreTabs from '../PreviewMoreTabs/PreviewMoreTabs'
import SearchBar from '../../../SearchBar/SearchBar'

const PreviewNavMobile = () => {
  console.log();

  return (
    <div className='previewNavMobile'>
        <PreviewMoreTabs/>
          {!location.pathname.includes('/group') && <SearchBar/>}
        <PreviewMenu/>
    </div>
  )
}

export default PreviewNavMobile